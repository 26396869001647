import { Link } from "@remix-run/react";
import { ArrowRightIcon } from "lucide-react";

interface HomeSectionProps {
  title: string;
  moreLink?: { to: string; text: string };
}
export const HomeSection = ({ title, children, moreLink }: React.PropsWithChildren<HomeSectionProps>) => {
  return (
    <div>
      <div className="mb-4 flex items-center justify-between">
        <h4 className="dark:text-gray-30 text-lg">{title}</h4>
        {moreLink && (
          <Link to={moreLink.to}>
            <span className="flex items-center gap-x-1 text-sm text-blue-50">
              {moreLink.text} <ArrowRightIcon className="h-4 w-4" />
            </span>
          </Link>
        )}
      </div>
      <div>{children}</div>
    </div>
  );
};
