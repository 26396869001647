import { Button, Container, Text } from "@fscrypto/ui";
import { Link } from "@remix-run/react";
import { track } from "../tracking";
import { rewards } from "@fscrypto/domain";

const handleTracking = (type: string) => {
  track(`click_homepage_banner_${type}`);
};

export const Hero = ({ rewardProgram }: { rewardProgram?: rewards.Program }) => {
  return (
    <Container className=" border-t">
      <div className="grid grid-cols-1 gap-6 py-8 md:grid-cols-2">
        <div className="dark flex w-full flex-col items-start space-y-4 rounded-lg bg-[#280051] bg-[url(https://res.cloudinary.com/dsr37ut2z/image/upload/f_auto,q_auto/v1/assets/backgrounds/fvtwaf7rl1p6zf1plpv3)] bg-contain bg-right-bottom bg-no-repeat p-8">
          <Text as="h2" weight="semi" size="2xl" color="contrast">
            Work with the best blockchain data
          </Text>
          <Text as="p" size="base" color="contrast" className="max-w-[450px] flex-1 pb-6 opacity-90">
            Query and build with the most reliable blockchain data. Get paid when your dashboards rank in the Top
            Dashboards.
          </Text>
          <div className="flex flex-col space-y-4 lg:flex-row lg:space-x-4 lg:space-y-0">
            <Button
              variant="primary"
              className="bg-[#972EFF] px-8 backdrop-blur hover:bg-[#741ECC]"
              onClick={() => handleTracking("Launch Studio")}
              asChild
            >
              <Link to="/edit">Access the data</Link>
            </Button>
            {rewardProgram && (
              <Button
                variant="primary"
                className="bg-[#A658FF] px-8 backdrop-blur hover:bg-[#741ECC]"
                onClick={() => handleTracking("Get paid")}
                asChild
              >
                <Link to={`/rewards/${rewardProgram.id}`}>Get paid for your dashboards</Link>
              </Button>
            )}
          </div>
        </div>

        <div className="dark flex w-full flex-col items-start space-y-4 rounded-lg bg-[#071557] bg-[url(https://res.cloudinary.com/dsr37ut2z/image/upload/f_auto,q_auto/v1/assets/backgrounds/hgxxv58dq3xbslfloart)] bg-contain bg-right-bottom bg-no-repeat p-8">
          <Text as="h2" weight="semi" size="2xl" color="contrast">
            Get paid to play onchain
          </Text>
          <Text as="p" size="base" color="contrast" className="max-w-[400px] flex-1 pb-6 opacity-90">
            Earn crypto for doing stuff onchain. Use top protocols to grow your onchain reputation.
          </Text>
          <Button variant="primary" className="px-8" onClick={() => handleTracking("Start Earning")} asChild>
            <Link to="/earn">Play to earn</Link>
          </Button>
        </div>
      </div>
    </Container>
  );
};
