import { TopAnalyst } from "@fscrypto/domain/user";
import { TopAnalystRow } from "./top-analyst-row";

interface TopAnalystsProps {
  topAnalysts: TopAnalyst[];
}
export const TopAnalysts = ({ topAnalysts }: TopAnalystsProps) => {
  return (
    <div className="flex flex-col gap-y-4">
      {topAnalysts.map((analyst) => {
        return <TopAnalystRow analyst={analyst} key={analyst.id} />;
      })}
    </div>
  );
};
