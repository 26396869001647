import config from "~/utils/config";
import { Tag } from "@fscrypto/ui";
import type { tag } from "@fscrypto/domain";

interface ProjectTagProps {
  tag: tag.Tag;
  onClick: () => void;
  selected?: boolean;
}

export const ProjectTag = ({ tag, onClick, selected }: ProjectTagProps) => {
  const imgSrc = config.CLOUDINARY_PROJECT_ICON_PATH + tag.iconFileName;
  return (
    <Tag
      size="md"
      variant={selected ? "filled" : "default"}
      prefix={<img className="h-5 w-5 rounded-full" src={imgSrc} alt={tag.name} />}
      asChild
    >
      <button onClick={onClick}>{tag.displayName}</button>
    </Tag>
  );
};
