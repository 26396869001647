import { TopAnalyst } from "@fscrypto/domain/user";
import { SiDiscord, SiX } from "@icons-pack/react-simple-icons";
import { useNavigate } from "@remix-run/react";
import { format } from "date-fns";
import { HeartIcon, TrophyIcon } from "lucide-react";
import { optimizeCloudinaryImage } from "~/utils/helpers";

interface TopAnalystRowProps {
  analyst: TopAnalyst;
}

export const TopAnalystRow = ({ analyst }: TopAnalystRowProps) => {
  const navigate = useNavigate();
  const joinedDate = format(analyst.createdAt, "MMM d, yyyy");
  return (
    <div
      className="border-stroke flex items-center gap-x-4 rounded-md border px-2 py-3 hover:cursor-pointer md:px-8"
      onClick={() => navigate(`/${analyst.username}`)}
    >
      <img className="h-12 w-12 rounded-full" src={optimizeCloudinaryImage(analyst.avatarUrl, 48) ?? ""} alt="" />
      <div className="block flex-grow md:hidden"></div>
      <div className="md:text-body my-1 flex flex-grow flex-col gap-y-2">
        <h4 className="dark:text-gray-30">{analyst.username}</h4>
        <div className="hidden flex-grow items-center gap-x-2 md:flex">
          <div className="text-gray-40 text-sm">Joined {joinedDate}</div>
          <AnalystPill>
            <HeartIcon className="size-3 fill-red-600 text-red-600" />
            <div className="ml-2">{analyst.dashboardLikes}</div>
          </AnalystPill>
          <AnalystPill>
            <SiDiscord className="size-3 text-indigo-500" />
            <span className="ml-2">{analyst.discordHandle}</span>
          </AnalystPill>
          <AnalystPill>
            <a
              href={`https://twitter.com/${analyst.twitterHandle}`}
              onClick={(e) => e.stopPropagation()}
              target="_blank"
              rel="noreferrer"
              className="z-10 flex items-center"
            >
              <SiX className="text-black dark:text-white size-3" />
              <span className="ml-2">{analyst.twitterHandle}</span>
            </a>
          </AnalystPill>
        </div>
      </div>
      <div className="flex items-center gap-x-1 text-sm md:gap-x-3 md:text-lg">
        <TrophyIcon className="size-4 text-yellow-400" />
        <div className="dark:text-gray-30">{analyst.rank}</div>
      </div>
    </div>
  );
};

const AnalystPill = ({ children }: { children: React.ReactNode }) => (
  <div className="text-gray-70 dark:text-gray-30 flex h-6 items-center rounded-full border px-2 text-sm shadow-sm">
    {children}
  </div>
);
