import { GrailButton } from "~/features/grail/button";
import { ProjectIcon } from "../earn/components/project-icon";

export function GrailBanner() {
  return null; // grail is over for now, dont show anything, remove this when grail is back and update the code below
  return (
    <div
      className="dark flex flex-col items-center justify-center space-x-3 space-y-3 bg-[#080808] bg-no-repeat py-6 lg:h-20 lg:flex-row lg:space-y-0 lg:py-0"
      style={{
        backgroundPosition: "top center",
        backgroundSize: "30%",
      }}
    >
      <ProjectIcon iconFileName="aptos" className="h-10" />
      <h1 className="text-xl text-white lg:text-base">Let's play a game.</h1>
      <p className="text-white/60">Grail is a new type of on-chain game launching first on Aptos.</p>
      <GrailButton className="h-10" asChild>
        <a href="/grail/aptos/s1">Enroll Today</a>
      </GrailButton>
    </div>
  );
}
